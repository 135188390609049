/* Australia - Green */
#AU {
  fill: #228B22; /* Forest Green */
}
#AU:hover {
  fill: #006400; /* Dark Green */
}

/* Sri Lanka - Dark Red */
#LK {
  fill: #8B0000; /* Dark Red */
}
#LK:hover {
  fill: #A52A2A; /* Slightly brighter red */
}

/* Sweden and Norway - White */
#SE, #NO {
  fill: #FFFFFF; /* White */
}
#SE:hover, #NO:hover {
  fill: #D3D3D3; /* Light Gray */
}

/* United States - Blue */
#US {
  fill: #0000FF; /* Blue */
}
#US:hover {
  fill: #00008B; /* Dark Blue */
}

/* General styles */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
html, body {
  height: 100%;
}
body {
  background: #21252b;
  color: #d3ddef;
}

.ag-canvas {
  display: flex;
  align-items: center;
  position: relative;
  visibility: visible;
  width: 100%;
  height: 100%;
  padding: 3rem 1rem;
  opacity: 1;
  transition: opacity 0.5s;
}

.ag-canvas_svg {
  display: block;
  width: 100%;
  height: auto;
  max-height: 100%;
  margin-left: 1rem;
}

.ag-canvas_svg path {
  fill: #383d46;
  fill-opacity: 1;
  stroke: #21252b;
  stroke-opacity: 1;
  stroke-width: 0.5;
  transition: fill 0.5s;
}

@media screen and (max-width: 767px) {
  .ag-canvas {
    flex-flow: row wrap;
    height: auto;
    padding: 4rem 1rem 1rem;
  }
  .ag-canvas_svg {
    max-height: calc(100% - 6rem);
    margin-left: 0;
  }
}
